import Vue from "vue";
import Component from "vue-class-component";

@Component
export class HasRegisterNumber extends Vue {
  isValidRegisterNumber(registerNumber: string) {
    if (!registerNumber) {
      return false;
    }

    let NrNValidation = this.validateNRN(registerNumber.replaceAll(".", "").replaceAll("-", "").replaceAll(" ", ""));

    return NrNValidation.valid;
  }

  /**s
   * @description check if we can get a birthdate from a given register number
   *
   * @param {string} registerNumber
   */
  getBirthDateFromRegisterNumber(registerNumber: string) {
    if (!registerNumber) {
      return "";
    }

    let NrNValidation = this.validateNRN(registerNumber.replaceAll(".", "").replaceAll("-", "").replaceAll(" ", ""));

    if (NrNValidation.valid) {
      let decade = NrNValidation.decade;

      let [year, month, day] = registerNumber.slice(0, 8).split(".");

      return `${decade}${year}-${month}-${day}`;
    }

    return "";
  }

  validateNRN(n: string) {
    if (n.length != 11) return { valid: false };

    var checkDigit = n.substr(n.length - 2, 2);
    var nrToCheck = parseInt(n.substr(0, 9));

    //@ts-ignore
    if (this.modFunction(nrToCheck) == checkDigit) return { valid: true, decade: 19 };

    nrToCheck = parseInt("2" + n.substr(0, 9));

    //@ts-ignore
    if (this.modFunction(nrToCheck) == checkDigit) {
      return { valid: true, decade: 20 };
    }

    return { valid: false };
  }

  modFunction(nr: any) {
    return 97 - (nr % 97);
  }
}
