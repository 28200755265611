import Vue from "vue";
import Component from "vue-class-component";
import * as RepresentativeEnums from "@/enums/representative";
import { Mixins } from "vue-property-decorator";
import { HasEnumMethods } from "../has-enum-methods";

@Component
export class HasRepresentativeOptions extends Mixins(HasEnumMethods) {
  RepresentativeTitleOptions = this.optionsFromEnum(RepresentativeEnums.RepresentativeTitle, "representative.enums.title");
  RepresentativeMaritalStateOptions = this.optionsFromEnum(RepresentativeEnums.RepresentativeMaritalState, "representative.enums.marital_state");
}
